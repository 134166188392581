<template>
  <div class="czp">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
      <el-breadcrumb-item :to="{ path: '/chome' }">企业首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/personindex' }">企业招聘列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <formlist class="fll sxx" :arr="shuaixuan">
        <el-button type="primary" size="mini" style="margin: 8px 0 0 8px">搜索</el-button>
        <el-button type="" size="mini" style="margin: 8px 0 0 8px">重置</el-button>
      </formlist>
      <div>
        <div  v-for="(item,index) in items" :key="index">
          <div class="title">{{item.title}}</div>
          <div class="flex">
            <div>{{item.money}}</div>
            <div>{{item.money}}</div>
            <div></div>
          </div>
          <div>{{item.desc}}</div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            page-size="15"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import formlist from "../../components/formlist";
export default {
  name: "czp",
  data(){
    return {
      pageNo:1,
      total:1,
      items:[{

      }],
      shuaixuan:[{
        type:'select',
        inputTitle:'职位类别',
        options:[{
          optionTitle:'111',
          inputValue:'222'
        }]
      },{
        type:'text',
        inputTitle:'关键字',
      }]
    }
  },
  components:{
    formlist
  }
}
</script>

<style lang="scss" scoped>
  .czp{
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .sxx{
    padding: 30px;
    /deep/ .list{
      display: block!important;
    }
    /deep/ .label{
      min-width: 120px;
    }
  }
  .fenye{
    text-align: center;
    padding: 30px 0;
  }
</style>